@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Lora:wght@600&family=Merriweather&family=Nunito:wght@200&family=Open+Sans&family=Roboto&display=swap");

.home-content {
  overflow: hidden;
  position: relative;
}

.banner {
  background: url("../../asset/img/banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  padding: 20px 0px;
  /* min-height: 800px !important; */
}

.banner .booking-info {
  /* background-color: #150f12; */
  color: #af8365;
  padding: 70px 20px 100px 120px !important;
  font-size: 15.5px !important;
}

.banner .booking-btn {
  width: 200px;
  margin: 2rem 0px;
  height: 60px;
  background-color: #af8365;
  border: 0;
  border-radius: 30px;
  font-weight: bold;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
}

.banner .title-banner,
.banner .home-title-banner {
  font-size: 50px;
  font-family: "Merriweather", serif;
}

.banner .branch-title {
  font-size: 30px;
  font-family: "Merriweather", serif;
  color: #442402;
}

.banner .location-list {
  display: flex;
}

.home-title-banner {
  color: #edccc0 !important;
}

.home-info {
  font-size: 25px;
  color: #442402 !important;
}

.our-service {
  /* background: url("../../asset/img/bg-our-service-3.webp");
  background-size: cover; 
  background-repeat: no-repeat;
  height: auto;
  padding:30px 0px; */
  background-color: #af8365;
}

.our-service .carousel-service {
  /* background-color:white; */
  padding: 40px;
}

.our-service .service-items {
  /* border: 2px dashed #0055a5; */
  /* background-color: var(--ant-primary-color);  */
  border-width: 3px;
  border-radius: 10px;
  /* padding:20px; */
  margin: 4rem 0rem;
  font-weight: bold;
  /* font-size:17px; */
  height: auto;
  min-height: 680px;
  font-family: "Merriweather", serif;
}

.our-service .service-items h2 {
  font-weight: 900;
  font-family: "Merriweather", serif;
  color: white !important;
}

.our-service .title-service {
  font-size: medium;
  color: white;
}

.our-service .title-service h2 {
  font-size: 40px;
  font-weight: 900 !important;
  color: white !important;
  text-shadow: 2px 2px var(--ant-primary-9);
}

.our-service .btn-switch {
  color: var(--ant-primary-6) !important;
}

.our-service .btn-switch:hover {
  color: rgb(84, 120, 205) !important;
}

.our-service .card-item-service {
  border: 1.5px solid var(--ant-primary-7);
  border-radius: 10px;
  /* margin:0px 10px; */
  text-align: center;
  min-height: 100px;
  height: auto;
  background-color: var(--ant-primary-1);
  box-shadow: 1px 2px 5px 2px rgba(75, 75, 75, 0.768);
  transition: all 0.3s;
}

.our-service .card-item-service:hover {
  transform: scale(1.025);
}

.our-service .card-item-line {
  border-bottom: 1.5px solid var(--ant-primary-7);
}

.section-gallery {
  background: url("../../asset/img/bg-gallery-4.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  color: #af8365;
  width: 100%;
  min-height: 600px;
  height: auto;
  font-family: "Merriweather", serif;
  font-size: large;
  display: flex;
  justify-content: center;
  padding: 120px 30px;
}

.section-gallery h1 {
  font-size: 40px;
  font-weight: bolder !important;
  color: var(--ant-primary-9) !important;
}

.gallery-carousel {
  width: 100%;
  height: auto;
}

.img-carousel {
  width: 100%;
  max-height: 500px;
  height: auto;
  margin: 0px 1px;
  padding: 0 10px;
}

/* .slick-slider {
  padding:0px 30px;
} */

.slick-prev:before,
.slick-next:before {
  width: 30px !important;
  height: 30px !important;
}

.slick-prev:before,
.slick-next:before {
  color: #af8365;
}

.home-general-info {
  background-color: #af8365;
  min-height: 500px;
  height: auto;
  width: 100%;
  /* color:white; */
  font-size: medium;
  border-bottom: 0.2px solid rgba(256, 255, 255, 0.4);
  padding-bottom: 60px;
}

.home-general-info h3 {
  color: white !important;
  font-weight: bolder !important;
}

.opening-hours {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid rgba(256, 255, 255, 0.4);
  margin: 0.7rem 0rem;
  color: var(--ant-primary-6);
}

.home-general-info .address-text {
  color: var(--ant-primary-6) !important;
}

.location-list {
  margin-top: 70px;
  width: 100%;
}

.banner .align-end,
.banner .align-start {
  display: flex;
  width: 50%;
}

.home-location-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; /* Center the items horizontally */
  width: 60%; /* Ensure the item takes the full width of its container */
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #cea893;
  border-radius: 10px;
  height: 600px;
  margin-right: 15%;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2),
    /* Slight offset shadow */ 8px 8px 20px rgba(0, 0, 0, 0.25),
    /* Deeper shadow for depth */ 12px 12px 30px rgba(0, 0, 0, 0.3),
    /* Additional layer for more depth */ inset 0px 0px 15px rgba(0, 0, 0, 0.2); /* Inner shadow for 3D effect */
  background-color: #442402;
}

.home-location-item-name {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  align-items: center; /* Ensure the text is aligned to the start */
  width: 100%; /* Ensures the text container takes up the full width */
  margin-bottom: 15px;
}

.home-location-item-text {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start; /* Ensure the text is aligned to the start */
  width: 100%; /* Ensures the text container takes up the full width */
  margin-bottom: auto; /* Pushes the text to the top */
}

.home-location-item-img {
  margin-bottom: 16px;
}

.home-location-item-img-placeholder {
  margin-bottom: 16px;
}

.home-item-enter-btn {
  background-color: #a05b25; /* Brown color */
  color: #edccc0; /* Text color */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  font-weight: bold; /* Bold text */
  display: inline-block;
  text-align: center;
  font-size: 16px;
  width: 150px; /* Adjust based on your button size */
  height: 50px; /* Adjust based on your button size */
  margin-bottom: 20px;
}

.home-item-enter-btn:hover {
  background-color: #8b491f; /* Darker brown when hovered */
  cursor: pointer;
}

.location-title {
  color: #af8365;
  font-size: 20px;
}

.location-info {
  color: #edccc0;
}

.list-item {
  display: flex;
}

.location-list .align-start {
  justify-content: flex-start !important;
}

.location-list .align-end {
  justify-content: flex-end !important;
  /* margin-right: 10%; */
}

/* ===========MODAL BOOKING ADDD============= */

.btn-booking-continue,
.btn-booking-confirm,
.btn-booking-done {
  background-color: #af8365 !important;
  width: 100%;
  height: 36px;
}

.btn-booking-confirm-disabled,
.btn-booking-continue-disabled {
  background-color: gray;
  width: 100%;
  height: 36px;
}

.btn-booking-continue:hover {
  background-color: #af8365;
}

.btn-booking-cancel {
  border: 0.4px solid rgba(195, 195, 195, 0.5);
  color: black !important;
  width: 100%;
  height: 36px;
}

.btn-booking-cancel:hover {
  color: #af8365;
  border: 0.4px solid rgba(195, 195, 195, 0.5);
  background-color: rgba(184, 182, 182, 0.572);
}

.modal-booking .ant-modal-title {
  font-size: 20px;
  color: var(--ant-primary-9);
  font-weight: bold;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-radio-checked .ant-radio-inner,
.ant-radio-button-checked,
.ant-radio-button-inner {
  background-color: #af8365;
  color: white;
}

.btn-link {
  color: rgb(43, 118, 222);
}

.btn-link:hover {
  color: rgb(8, 94, 214);
}

.modal-header,
.ant-collapse-header-text,
.title-time {
  color: var(--ant-primary-9) !important;
  font-size: medium;
  font-weight: bolder !important;
}

.success-booking-title {
  color: #323232 !important;
  font-size: 30px;
  font-weight: 1000 !important;
}

.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  border: 1px solid #444;
}

.ant-checkbox-wrapper {
  width: 100%;
}

.checkbox-item {
  display: flex;
  align-items: center;
}

.calender-booking {
  border: 0.5px solid rgba(153, 153, 153, 0.54);
  border-radius: 10px;
}

.PhoneInputInput {
  border: 0;
}

.booking-selected {
  border: 0.4px solid rgba(153, 153, 153, 0.54);
  border-radius: 5px;
  margin-top: 0.5rem;
}

.note-booking {
  margin: 20px 0px;
  text-align: justify;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed rgba(217, 217, 217, 0.857);
  background-color: rgba(213, 213, 213, 0.448);
}

/* xs */
@media (max-width: 576px) {
  .banner .booking-info {
    padding: 20px 40px !important;
  }
  .ant-collapse-header-text {
    font-size: small;
  }

  .ant-modal-title {
    font-size: 15px;
  }

  .home-title-banner {
    font-size: 30px !important;
  }

  .branch-title {
    font-size: 20px !important;
  }

  .home-info {
    font-size: 15px;
  }

  .list-item {
    justify-content: center !important;
  }

  .home-location-item {
    width: 100%;
    height: 500px;
    margin-right: 0;
  }

  .location-list {
    margin-top: 40px;
  }
}
/* md */
@media (max-width: 768px) {
  .banner .booking-info {
    padding: 20px 40px !important;
  }

  .location-list .align-end {
    margin-right: 0;
  }

  .home-item-enter-btn {
    font-size: 12px;
    width: 80px; /* Adjust based on your button size */
    height: 40px; /* Adjust based on your button size */
    margin-bottom: 20px;
  }

  .ant-collapse-header-text {
    font-size: small;
  }
  .ant-modal-title {
    font-size: 15px;
  }

  .home-title-banner {
    font-size: 30px !important;
  }

  .branch-title {
    font-size: 20px !important;
  }

  .home-info {
    font-size: 15px;
  }
}

#home-maintain {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: white;
  z-index: 1;
}

.upload-files .ant-upload-list-item-actions .anticon-eye {
  display: none !important;
}

.attached-file {
  width: 100px !important;
}

.xmas-bg {
  width: 100%;
  text-shadow: 1px 1px 3px #000000;
  height: 60px;
  background-image: url("../../asset/img/XMAS.jpg") !important;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.xmas-bg-btn {
  background-color: rgba(165, 3, 3, 0.714);
}

.xmas-bg-btn:hover {
  background-color: rgba(204, 57, 57, 0.714);
}

.fs-20 {
  font-size: 20px;
}

.text-under-line {
  text-decoration: underline;
}

.mt-10 {
  margin-top: 10px;
}

.w-400 {
  max-width: 400px;
}

/* ===========SNOW ANIMATION============= */
