.booking-banner {
  background: url("../../../asset/img/banner-booking.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  padding: 20px 0px;
  /* min-height: 800px !important; */
}

.booking-banner .booking-info {
  /* background-color: #150f12; */
  color: #af8365;
  padding: 100px 20px 200px 120px !important;
  font-size: 15.5px !important;
}

.booking-banner .booking-btn {
  width: 200px;
  margin: 2rem 0px;
  height: 60px;
  background-color: #af8365;
  border: 0;
  border-radius: 30px;
  font-weight: bold;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
}

.booking-banner .title-banner {
  font-size: 50px;
  font-family: "Merriweather", serif;
}

.booking-banner .branch-title {
  font-size: 30px;
  font-family: "Merriweather", serif;
  color: #442402;
}

.branch-footermobile-mt {
  margin-top: 3.8rem;
}

@media (max-width: 576px) {
  .booking-banner .booking-info {
    padding: 20px 50px !important;
  }

  .branch-footermobile-mt {
    margin-top: 6rem;
  }

  .booking-banner .title-banner {
    font-size: 25px !important;
  }
}

@media (max-width: 768px) {
  .booking-banner .booking-info {
    padding: 20px 50px !important;
  }
}
