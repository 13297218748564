.branch-banner {
  background: url("../../../asset/img/banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  padding: 20px 0px;
  /* min-height: 800px !important; */
}

.branch-banner .booking-info {
  /* background-color: #150f12; */
  color: #af8365;
  padding: 70px 20px 100px 120px !important;
  font-size: 15.5px !important;
}

.branch-banner .booking-btn {
  width: 200px;
  margin: 2rem 0px;
  height: 60px;
  background-color: #af8365;
  border: 0;
  border-radius: 30px;
  font-weight: bold;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
}

.branch-banner .title-banner {
  font-size: 50px;
  font-family: "Merriweather", serif;
}

.branch-banner .branch-title {
  font-size: 30px;
  font-family: "Merriweather", serif;
  color: #442402;
}

.branch-banner .location-list {
  display: flex;
}

.branch-banner .align-end,
.branch-banner .align-start {
  display: flex;
  width: 50%;
}

.branch-booking-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; /* Center the items horizontally */
  width: 67%; /* Ensure the item takes the full width of its container */
  padding: 10px 1px;
  box-sizing: border-box;
  border: 1px solid #cea893;
  border-radius: 35px;
  /* height: 560px; */
  margin-right: 15%;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2),
    /* Slight offset shadow */ 8px 8px 20px rgba(0, 0, 0, 0.25),
    /* Deeper shadow for depth */ 12px 12px 30px rgba(0, 0, 0, 0.3),
    /* Additional layer for more depth */ inset 0px 0px 15px rgba(0, 0, 0, 0.2); /* Inner shadow for 3D effect */
  background-color: #442402;
}

@media (max-width: 576px) {
  .branch-banner .booking-info {
    padding: 20px 40px !important;
  }

  .branch-banner .align-end,
  .branch-banner .align-start {
    display: flex;
    width: 100%;
  }

  .branch-booking-item {
    width: 90%;
    /* height: 500px; */
    margin-right: 10px;
    padding: 6px 3px;
  }
}

@media (max-width: 768px) {
  .branch-banner .branch-title {
    padding-left: 10px;
  }

  .branch-banner .booking-info {
    padding: 20px 20px !important;
  }

  /* .branch-banner .location-list {
    display: block; 
  } */

  .branch-banner .align-end,
  .branch-banner .align-start {
    display: flex;
    width: 100%;
  }

  .branch-booking-item {
    padding: 4px 8px;
    width: 90%;
    /* height: 500px; */
    margin-right: 10px;
  }
}

@media (max-width: 1080px) {
  .branch-booking-item {
    padding: 10px 3px;
    border-radius: 20px;
  }
}
